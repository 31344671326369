<template>
  <v-app>
    <v-main>
      <router-view name="main"></router-view>
      <v-navigation-drawer
        style="overflow: visible; height: 100% !important;"
        :width="config.panelWidth"
        :value="showPanel"
        app
        stateless
        temporary
        touchless
        right
      >
        <v-btn
          fab
          small
          elevation="0"
          color="#F0F0F0"
          class="ml-close-panel-btn-mobile hidden-md-and-up"
          @click.stop="togglePanel"
          v-if="isShowMobileCloseBtn"
        >
          <v-icon color="#4D4D4D">mdi-close</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          color="#F0F0F0"
          class="ml-close-panel-btn hidden-sm-and-down"
          @click.stop="togglePanel"
        >
          <v-icon color="#4D4D4D">mdi-close</v-icon>
        </v-btn>
        <div id="widget-wrapper" ref="widget-wrapper"></div>
      </v-navigation-drawer>

      <v-navigation-drawer
        style="overflow: visible; height: 100% !important;"
        :width="config.panelWidth"
        :value="showPanelBalance"
        app
        stateless
        temporary
        touchless
        right
      >
        <v-btn
          fab
          small
          elevation="0"
          color="#F0F0F0"
          class="ml-close-panel-btn-mobile hidden-md-and-up"
          @click.stop="togglePanelBalance"
        >
          <v-icon color="#4D4D4D">mdi-close</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          color="#F0F0F0"
          class="ml-close-panel-btn hidden-sm-and-down"
          @click.stop="togglePanelBalance"
        >
          <v-icon color="#4D4D4D">mdi-close</v-icon>
        </v-btn>
        <div id="widget-balance-wrapper" ref="widget-balance-wrapper"></div>
      </v-navigation-drawer>

      <!-- BASKET PANEL -->
      <v-navigation-drawer
        style="overflow: visible; height: 100% !important;"
        :width="config.panelWidth"
        :value="showPanelBasket"
        app
        stateless
        temporary
        touchless
        right
      >
        <v-btn
          fab
          small
          elevation="0"
          color="#F0F0F0"
          class="ml-close-panel-btn-mobile hidden-md-and-up"
          @click.stop="togglePanelBasket"
        >
          <v-icon color="#4D4D4D">mdi-close</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          color="#F0F0F0"
          class="ml-close-panel-btn hidden-sm-and-down"
          @click.stop="togglePanelBasket"
        >
          <v-icon color="#4D4D4D">mdi-close</v-icon>
        </v-btn>
        <div id="widget-basket-wrapper" ref="widget-basket-wrapper"></div>
      </v-navigation-drawer>

      <!-- SUBSCRIBE PANEL -->
      <v-navigation-drawer
        style="overflow: visible; height: 100% !important;"
        :width="config.panelWidth"
        :value="showPanelSubscribe"
        app
        stateless
        temporary
        touchless
        right
      >
        <v-btn
          fab
          small
          elevation="0"
          color="#F0F0F0"
          class="ml-close-panel-btn-mobile hidden-md-and-up"
          @click.stop="togglePanelSubscribe"
        >
          <v-icon color="#4D4D4D">mdi-close</v-icon>
        </v-btn>
        <v-btn
          fab
          small
          color="#F0F0F0"
          class="ml-close-panel-btn hidden-sm-and-down"
          @click.stop="togglePanelSubscribe"
        >
          <v-icon color="#4D4D4D">mdi-close</v-icon>
        </v-btn>
        <div id="widget-subscribe-wrapper" ref="widget-subscribe-wrapper"></div>
      </v-navigation-drawer>
    </v-main>
  </v-app>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import panelTypes from '@/store/panel/types'
import MixinChangePanelPage from '@/helpers/mixins/panel/changePage'

export default {
  mixins: [MixinChangePanelPage],
  computed: {
    ...mapState({
      showPanel: state => state.panel.show,
      showPanelBalance: state => state.panel.showPanelBalance,
      showPanelBasket: state => state.panel.showPanelBasket,
      showPanelSubscribe: state => state.panel.showPanelSubscribe,
      config: state => state.app.config
    })
  },
  data() {
    return {
      counter: 1,
      code: 'test-code',
      isShowMobileCloseBtn: true,
      drawer: false,
      items: [
        { title: 'Home', icon: 'mdi-view-dashboard' },
        { title: 'About', icon: 'mdi-forum' }
      ]
    }
  },
  methods: {
    ...mapMutations('panel', [
      panelTypes.TOGGLE_PANEL,
      panelTypes.TOGGLE_PANEL_BALANCE,
      panelTypes.TOGGLE_PANEL_BASKET,
      panelTypes.TOGGLE_PANEL_SUBSCRIBE
    ]),

    togglePanel() {
      const isShow = !this.showPanel
      // if (isShow === true) {
      //   this.initWidget()
      // }
      // if (this.showPanel === false) {
      // }
      this[panelTypes.TOGGLE_PANEL](isShow)
      // this.changePanelPage(null)
    },
    togglePanelBalance() {
      this[panelTypes.TOGGLE_PANEL_BALANCE](!this.showPanelBalance)
    },
    togglePanelBasket() {
      this[panelTypes.TOGGLE_PANEL_BASKET](!this.showPanelBasket)
    },
    togglePanelSubscribe() {
      this[panelTypes.TOGGLE_PANEL_SUBSCRIBE](!this.showPanelSubscribe)
    },
    // Render the component
    isMobile() {
      // credit to Timothy Huang for this regex test:
      // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    },
    initWidgetSubscribe() {
      this.$refs['widget-subscribe-wrapper'].innerHTML = null
      MloyaltyWidget({
        code: 'Подписка',
        onHide: () => {},
        hostname: 'localhost:8080',
        protocol: 'https',
        pathname: '/'
      }).render('#widget-subscribe-wrapper')
    },
    initWidgetBalance() {
      this.$refs['widget-balance-wrapper'].innerHTML = null
      MloyaltyWidget({
        code: 'Баланс',
        onHide: () => {},
        hostname: 'localhost:8080',
        protocol: 'https',
        pathname: '/'
      }).render('#widget-balance-wrapper')
    },
    initWidgetBasket() {
      this.$refs['widget-basket-wrapper'].innerHTML = null
      MloyaltyWidget({
        code: 'Готовая корзина',
        onHide: () => {},
        hostname: 'localhost:8080',
        protocol: 'https',
        pathname: '/'
      }).render('#widget-basket-wrapper')
    },
    initWidget() {
      this.$refs['widget-wrapper'].innerHTML = null
      MloyaltyWidget({
        code: 'Купить',
        onHide: value => {
          this.$set(this, 'isShowMobileCloseBtn', !value)
        },
        hostname: 'localhost:8080',
        protocol: 'https',
        pathname: '/'
      }).render('#widget-wrapper')
    },
    initAllWidgets() {
      this.initWidget()
      this.initWidgetBalance()
      this.initWidgetBasket()
      this.initWidgetSubscribe()
    }
  },
  watch: {
    showPanelBalance(value) {
      if (value) {
        // this.$refs.balance.resetForm()
      }
    }
  },
  mounted() {
    // this.initAllWidgets()
  }
}
</script>
