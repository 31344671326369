import types from './types'

export default {
  [types.CURRENT_PAGE_SET]: (state, page) => {
    state.page = page
  },
  [types.TOGGLE_PANEL]: (state, isShow) => {
    // console.log(isShow)
    state.show = isShow
  },
  [types.TOGGLE_PANEL_BALANCE]: (state, isShow) => {
    state.showPanelBalance = isShow
  },
  [types.TOGGLE_PANEL_BASKET]: (state, isShow) => {
    state.showPanelBasket = isShow
  },
  [types.TOGGLE_PANEL_SUBSCRIBE]: (state, isShow) => {
    state.showPanelSubscribe = isShow
  }
}
