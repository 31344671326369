import types from './types'
import { apiInstance } from '@/api'

export default {
  [types.INIT_TEMPLATE]: async ({ commit }, payload) => {
    commit(types.TEMPLATE_CODE_SET, payload)
  },
  [types.SITE_CONFIG_ACTION]: async ({ commit }, payload) => {
    const siteConfig = await apiInstance.get('sertcard')
    commit(types.SET_SITE_CONFIG, siteConfig.data)
  }
}
