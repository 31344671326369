export default {
  INIT_EMPTY_BASKET: 'INIT_EMPTY_BASKET',
  INIT_TEST_BASKET: 'INIT_TEST_BASKET',
  // ADD_CERTIFICATE_TO_BASKET: 'ADD_CERTIFICATE_TO_BASKET',
  UPDATE_CERTIFICATE_ACTION: 'UPDATE_CERTIFICATE_ACTION',
  //Mutations
  SET_BASKET: 'SET_BASKET',
  ADD_CERTIFICATE: 'ADD_CERTIFICATE',
  UPDATE_CERTIFICATE: 'UPDATE_CERTIFICATE',
  CANCEL_REMOVE_CERTIFICATE: 'CANCEL_REMOVE_CERTIFICATE',
  REMOVE_CERTIFICATE: 'REMOVE_CERTIFICATE',
  SET_CURRENT_CERTIFICATE: 'SET_CURRENT_CERTIFICATE'
}
