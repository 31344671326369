import types from './types'
import Vue from 'vue'

export default {
  [types.TEMPLATE_CODE_SET]: (state, code) => {
    state.code = code
  },
  [types.SET_LOADING_APP]: (state, loading) => {
    state.loading = loading
  },
  [types.SET_SITE_CONFIG]: (state, payload) => {
    state.config.buttons = Vue.set(state.config, 'buttons', payload.buttons)
  }
}
